import React from 'react'
import { Link } from 'react-router-dom';

const SayongbangbubPage = () => {
  return (

    <div class="noScroll">

      <div class="row">

      <p class="navBarFont">
        <Link to="/" class="navBarFont">GIstock</Link>
        <Link to="/" class="navBarFont2">메인</Link>
        <Link to="/SayongbangbubPage" class="navBarFont3">사용방법</Link>
        <Link to="/GeabaljaPage" class="navBarFont4">개발자</Link>
      </p>

      </div> 


      <div>
        <div class="container-fluid bg-warning-subtle">

          <div class="row">
            <div class="col">
              
              <p class="sayongbangbubFont1">

                사용방법        

                <Link to="/SayongbangbubPage" class="navGJM2">재고파악</Link>
                &nbsp;
                <Link to="/MagamPage" class="navGJM3">마감</Link>
              </p>
            
            </div>

          </div>

          <div class="row">
          <div class="col">
            <span class="sayongbangbubImage"><img src="SJ1.png" class="img-fluid" alt="..."></img>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col"><p class="sayongbangbubFont2">마감마감마감마감마감마감마감마감마감마감마감마감마감마감마감</p></div>
        </div>


        <div class="row">
          <div class="col">
            <span class="sayongbangbubImage2"><img src="SJ2.png" class="img-fluid" alt="..."></img>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col"><p class="sayongbangbubFont2">왼쪽에 전체 체크 후 '엑셀다운' 클릭합니다.</p></div>
        </div>

        <div class="row">
          <div class="col">
            <span class="sayongbangbubImage3"><img src="SJ3.png" class="img-fluid" alt="..."></img>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col"><p class="sayongbangbubFont2">비밀번호는 0000으로 설정한 후 다운로드 합니다.</p></div>
        </div>

        <div class="row">
          <div class="col">
            <span class="sayongbangbubImage4"><img src="SJ4.png" class="img-fluid" alt="..."></img>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col"><p class="sayongbangbubFont2">재고파악 폴더에 엑셀파일을 저장합니다.</p></div>
        </div>

        <div class="row">
          <div class="col">
            <span class="sayongbangbubImage5"><img src="SJ5.png" class="img-fluid" alt="..."></img>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col"><p class="sayongbangbubFont2">비밀번호는 아까 설정해 둔 0000을 입력합니다.</p></div>
        </div>

        <div class="row">
          <div class="col">
            <span class="sayongbangbubImage6"><img src="SJ6.png" class="img-fluid" alt="..."></img>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col"><p class="sayongbangbubFont2">'편집사용'버튼을 누릅니다.</p></div>
        </div>

        <div class="row">
          <div class="col">
            <span class="sayongbangbubImage7"><img src="SJ7.png" class="img-fluid" alt="..."></img>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col"><p class="sayongbangbubFont2">왼쪽 상단 위 '파일' 버튼을 누르고 정보칸을 누른 뒤 '통합문서보호' 를 누르고 '암호설정' 을 누릅니다.</p></div>
        </div>

        <div class="row">
          <div class="col">
            <span class="sayongbangbubImage8"><img src="SJ8.png" class="img-fluid" alt="..."></img>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col"><p class="sayongbangbubFont2">아까 설정한 비밀번호를 다 지웁니다.</p></div>
        </div>

        <div class="row">
          <div class="col">
            <span class="sayongbangbubImage9"><img src="SJ9.png" class="img-fluid" alt="..."></img>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col"><p class="sayongbangbubFont2">그 뒤 '저장' 버튼을 누릅니다.</p></div>
        </div>

        <div class="row">
          <div class="col">
            <span class="sayongbangbubImage10"><img src="SJ10.png" class="img-fluid" alt="..."></img>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col"><p class="sayongbangbubFont2">파일에 스마트스토어_선택주문발주발송관리를 넣고 '주문서 변환'을 클릭합니다.</p></div>
        </div>

        <div class="row">
          <div class="col">
            <span class="sayongbangbubImage11"><img src="SJ11.png" class="img-fluid" alt="..."></img>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col"><p class="sayongbangbubFont2">창고에 있는 재고와 오늘 주문들어온 주문서의 재고를 비교합니다.</p></div>
        </div>

        </div>
      </div>
    </div>




    

  )
}

export default SayongbangbubPage