import React from 'react'
import { Link } from 'react-router-dom';

const GeabaljaPage = () => {
  return (
    <div class="noScroll">

      <div class="row">

        <p class="navBarFont">
          <Link to="/" class="navBarFont">GIstock</Link>
          <Link to="/" class="navBarFont2">메인</Link>
          <Link to="/SayongbangbubPage" class="navBarFont3">사용방법</Link>
          <Link to="/GeabaljaPage" class="navBarFont4">개발자</Link>
        </p>

      </div> 


      <div class="container-fluid bg-warning-subtle">
        <div class="row">

          <p class="geabaljaPagefont">
            개발자
          </p>

          <div class="row">
            <div class="col bg-warning-subtle">
              <p class="imgCenter"><img src="GEK.png" class="img-fluid" alt="..."></img></p>
            </div>

            <div class="col bg-warning-subtle">
              <p class="imgCenter3"><img src="YHK.png" class="img-fluid" alt="..."></img></p>
            </div>

            <div class="col bg-warning-subtle">
              <p class="imgCenter2"><img src="JMK.png" class="img-fluid" alt="..."></img></p>
            </div>

          </div>

          <div class="row">
            <div class="col"><p class="geabaljaPagefont1">front-end: 김가은</p></div>
            <div class="col"><p class="geabaljaPagefont3">server: 김요한</p></div>
            <div class="col"><p class="geabaljaPagefont2">back-end: 김정민</p></div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default GeabaljaPage