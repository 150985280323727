import Home from './pages/Home';
import GeabaljaPage from './pages/GeabaljaPage';
import SayongbangbubPage from './pages/SayongbangbubPage';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import MagamPage from './pages/MagamPage';

let currentPath = "";

function App() {

  let location = useLocation();
  useEffect(()=>{
    if(currentPath === location.pathname) window.location.reload();

    currentPath = location.pathname;
  }, [location]);

  return (
    <div>

      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/sayongbangbubPage" element={<SayongbangbubPage />}/>
        <Route path="/Magampage" element={<MagamPage />}/>
        <Route path="/geabaljaPage" element={<GeabaljaPage />}/>
      </Routes>

      
    </div>
  );
}

export default App;
